.conteudo .bg-image {
    background-image: url('../assets/img/bg-essentia-group.png');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    padding: 82px 0px 36px;
}

.conteudo .container {
    max-width: 731px;
    margin: 0 auto;
}

.conteudo .logo-group {
    display: block;
    margin: 0px auto 60px;
}

.conteudo p {
    color: #ffffff;
    font-size: 17px;
    line-height: 22px;
    max-width: 409px;
    margin: 0 auto 20px;
    text-align: center;
}

.conteudo .title {
    font-family: "utopia-std-display", arial;
    font-style: italic;
    font-size: 46px;
    line-height: 50px;
    margin-bottom: 30px;
    max-width: 500px;
}

.conteudo .p-1 {
    font-family: "utopia-std-display", arial;
    font-weight: bold;
    font-style: italic;
    max-width: 380px;
}

.conteudo .p-4 {
    font-weight: bold;
    padding-top: 15px;
    border-top: 2px solid #B1904E;
    margin: 60px auto 34px;
    font-size: 20px;
    line-height: 22px;
    max-width: 292px;
}

.conteudo .logos-grupo {
    display: flex;
    gap: 32px;
    justify-content: center;
    align-items: baseline;
}

.mobile-logos-grupo {
    display: none;
}

.conteudo .logos-grupo img {
    display: block;
}

/*Bloco descrição das empresas*/
.conteudo .bg-empresas {
    background-color: #242424;
    padding: 67px 0px 20px;
}

.conteudo .bg-empresas p {
    text-align: left;
}

.conteudo .bg-empresas a {
    color: #B1904E;
    display: block;
    font-size: 17px;
}

.conteudo .d-flex {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    margin-bottom: 70px;
}

.conteudo .d-flex.gap-58 {
    gap: 58px;
}

.conteudo .d-flex.be-generous {
    gap: 29px;
}

.conteudo .d-flex.be-generous .texto p {
    max-width: 432px;
}

.conteudo .d-flex.noorskin .texto p {
    max-width: 419px;
}

.conteudo .d-flex.gap-58 .texto p {
    max-width: 402px;
}

.conteudo .be-generous .texto img {
    margin-bottom: 20px;
}

.conteudo .imagem img {
    max-width: 260px;
}

.conteudo .texto img {
    margin-bottom: 26px;
    display: block;
}

.conteudo a.site:hover {
    opacity: .8;
}

.conteudo p.p-17 {
    font-size: 16px;
    line-height: 21px;
    color: #B1904E;
    border-left: 1px solid #fff;
    padding-left: 10px;
}

@media (max-width: 767px) {
    .container {
        padding: 0 36px;
    }

    .conteudo p {
        max-width: 301px;
    }

    .conteudo .p-1 {
        max-width: 280px;
    }

    .conteudo .bg-image {
        background-image: url(../assets/img/mobile/bg-essentia-group.png);
        padding: 40px 0px 75px;
        background-position: right top;
    }

    .conteudo .logo-group {
        margin: 0px auto 50px;
    }

    .conteudo .title {
        font-size: 29px;
        line-height: 32px;
        margin-bottom: 20px;
    }

    .conteudo .p-4 {
        margin: 54px auto 31px;
        font-size: 16px;
        line-height: 18px;
        max-width: 230px;
    }

    .conteudo .d-flex {
        flex-direction: column;
        gap: 21px;
    }

    .conteudo .d-flex.column-reverse {
        flex-direction: column-reverse;
        gap: 21px;
    }

    .conteudo .logos-grupo {
        display: none;
    }

    .mobile-logos-grupo {
        display: block;
    }

    .conteudo .d-flex .texto p {
        margin: 0 0 12px;
        max-width: 260px;
        font-size: 16px;
        line-height: 20px;
    }

    .conteudo .bg-empresas {
        padding: 73px 0px 3px;
    }

    .conteudo .texto img {
        margin-bottom: 16px;
    }

    .conteudo .d-flex.gap-58 .texto p {
        max-width: 301px;
    }

    .conteudo .d-flex.noorskin .texto p {
        max-width: 279px;
    }

    .conteudo .d-flex.be-generous .texto p {
        max-width: 310px;
    }

    .conteudo .d-flex.easy-health .texto p {
        max-width: 292px;
    }

    .conteudo .imagem img {
        max-width: 100%;
        display: block;
    }

    /*Carrossel*/
    .conteudo .slick-prev,
    .conteudo .slick-next {
        transform: none;
        top: 103px;
    }

    .conteudo .slick-prev:before,
    .conteudo .slick-next:before {
        opacity: 1;
    }

    .conteudo .slick-prev {
        left: 44%;
    }

    .conteudo .slick-next {
        right: 44%;
    }

    .conteudo .slick-next:before {
        content: url('../assets/img/mobile/seta-esquerda.svg');
    }

    .conteudo .slick-prev:before {
        content: url('../assets/img/mobile/seta-direita.svg');
    }

    .conteudo .slick-slide>div {
        margin: 0 20px;
    }

    .conteudo .slick-track {
        display: flex;
        align-items: baseline;
    }
}