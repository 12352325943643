.rodape {
    background-color: #333333;
    width: 100%;
    height: 175px;
    display: flex;
    justify-content: center;
    align-self: center;
}

.rodape img {
    max-width: 177px;
}