@import url("https://use.typekit.net/tvf0cut.css");

body {
    margin: 0;
    padding: 0;
}

p,
a,
span,
h1,
button {
    font-family: "proxima-nova";
}

a,
button,
button:focus-visible {
    outline: none;
}

.logo-noorskin {
    max-width: 135px;
}