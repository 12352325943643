.hide {
    display: none !important;
}

body:has(.experiencePage) nav .dropdown {
    display: none;
}

body {
    -webkit-font-smoothing: antialiased;
    width: 100vw;
    overflow-x: hidden;
}

*, ::after, ::before {
    box-sizing: border-box;
}

img {
    max-width: 100%;
    height: auto;
    border: 0;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

p {
    font-size: 19px;
    line-height: 25px;
}

@media (min-width: 1270px) {
    .container {
        max-width: 1270px;
        margin: 0 auto;
    }
}

.row {
    display: -ms-flexbox;
    display: flex;
    margin-right: -15px;
    margin-left: -15px;
}

[class^="col-"] {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

@media (min-width: 576px) {
    .col-sm-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .col-sm-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        float: left;
    }

    .col-sm-offset-1 {
        margin-left: 8.33333333%;
    }
}

header {
    position: relative;
    z-index: 1;
}

.back01-02 {
    background: transparent linear-gradient(180deg, #030303 0%, #020202 52%, #000000 100%) 0% 0% no-repeat padding-box;
}

.section01 {
    background: #000;
    position: relative;
    min-height: 1075px;

    .bg_video {
        right: 0;
        bottom: 0;
        min-width: 100%;
        min-height: 100%;
        width: 100%;
        height: auto;
        /* background: url() no-repeat; */
        background-size: cover;
    }

    .blc-container {
        position: absolute;
        top: 160px;
        text-align: center;
        width: 100%;
    }

    img {
        max-width: 380px;
    }

    h1 {
        color: #F5F5F5;
        font-size: 48px;
        line-height: 56px;
        text-align: center;
        font-weight: 400;
        margin: 128px 0 0;

        span {
            font-family: "utopia-std-display", arial;
            font-weight: 600;
            font-style: italic;
        }
    }

    .subtitle {
        display: flex;
        align-items: center;
        gap: 18px;
        justify-content: center;
        color: #F5F5F5;
        font-size: 23px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 3.56px;
        margin: 39px 0 30px;

        &::before {
            content: '';
            display: inline-block;
            width: 30px;
            height: 2px;
            background-color: #fff;
        }

        &::before {
            content: '';
            display: inline-block;
            width: 30px;
            height: 2px;
            background-color: #fff;
        }

        &::after {
            content: '';
            display: inline-block;
            width: 30px;
            height: 2px;
            background-color: #fff;
        }
    }
}

.section02 {
    color: #F5F5F5;
    padding-top: 400px;
    padding-bottom: 90px;
    position: relative;

    .parallax-container {
        height: 100vh;
        overflow: hidden;
    }

    h2 {
        font-family: "proxima-nova";
        font-size: 80px;
        line-height: 86px;
        text-align: center;
        font-weight: 400;
        max-width: 730px;
        margin: 0 auto;

        em {
            font-family: "utopia-std-display", arial;
        }
    }

    h3 {
        font-family: "proxima-nova";
        font-size: 36px;
        line-height: 45px;
        text-align: center;
        font-weight: 400;
        margin: 135px auto 0;

        em {
            font-family: "utopia-std-display", arial;
            font-weight: 600;
        }
    }

    p {
        text-align: center;
    }

    .txt01 {
        max-width: 540px;
        margin: 42px auto 0;
    }

    .txt02 {
        max-width: 490px;
        margin: 14px auto 0;
    }

    .anime01, .anime02, .anime03 {
        position: absolute;
        /* transition: transform 0.1s ease-out; */
    }

    .anime01 {
        top: -270px;
        left: calc(50% - 231px);
    }

    .anime02 {
        left: calc(50% - 784px);
        top: 350px;
    }

    .anime03 {
        left: calc(50% + 455px);
        top: 620px;
    }
}

.section03 {
    background: #E2E2E2;
    position: relative;
    padding-top: 80px;

    .row {
        align-items: center;
        padding: 50px 0;
    }

    .col-sm-6 {
        padding: 0;
    }

    .txt01 {
        max-width: 403px;
    }

    .txt02 {
        max-width: 348px;
    }

    .txt03 {
        max-width: 393px;
    }
}

.section04 {
    background: #E2E2E2;
    padding-bottom: 100px;
    position: relative;

    h2 {
        font-family: "proxima-nova";
        font-size: 48px;
        line-height: 56px;
        text-align: center;
        font-weight: 400;
        max-width: 630px;
        margin: 100px auto 50px;

        em {
            font-family: "utopia-std-display", arial;
            font-weight: 600;
        }
    }

    p {
        font-size: 18px;
        line-height: 24px;
        margin: 0;
        text-align: center;
    }

    .divisor {
        width: 92px;
        height: 2px;
        background: #C79E75 0% 0% no-repeat padding-box;
        display: block;
        margin: 24px auto 20px;
    }
}

.letterAnim {
    width: 100%;
    overflow: hidden;
}

.letter01 {
    margin-bottom: 100px;
}

.letter02 {
    margin-top: 75px;
}

.animTextBack {
    background-image: url(../assets/img/experience/letter-essentia-experience.svg);
    background-repeat: repeat-x;
    background-size: 418px auto;
    animation: marquee 4s infinite linear;
    margin-left: 0;
    height: 20px;
}

@keyframes marquee {
    0% {
        background-position: 0;
    }

    100% {
        background-position: -418px;
    }
}

.rodape {
    position: relative;
}

@media (max-width: 767px) {
    .desktop {
        display: none;
    }

    .row {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    p {
        font-size: 17px;
        line-height: 22px;
    }

    .section01 {
        min-height: 517px;

        h1 {
            font-size: 29px;
            max-width: 250px;
            line-height: 31px;
            margin: 65px auto 0;
        }

        .subtitle {
            gap: 11px;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 2.17px;
            margin: 16px 0 47px;
        }

        .bg_video {
            /* height: 100vh; */
        }

        .blc-container {
            top: 55px;

            img {
                max-width: 181px;
            }
        }
    }

    .section02 {
        padding-top: 60px;
        margin-top: -150px;
        padding-top: 80px;

        h2 {
            font-size: 35px;
            line-height: 37px;
        }

        h3 {
            font-size: 25px;
            line-height: 28px;
            margin: 43px auto 0;
            max-width: 307px;
        }

        .txt01 {
            max-width: 302px;
            margin: 23px auto 0;
        }

        .txt02 {
            max-width: 288px;
            margin: 11px auto 0;
        }

        .anime01 {
            top: -120px;
            max-width: 190px;
            left: calc(50% - 95px);
        }
    }

    .section03 {
        padding-top: 40px;

        .container {
            padding: 0 32px;
        }

        .letter01 {
            margin-bottom: 0;
        }

        .col-12 {
            padding: 0;
        }

        .row {
            margin: 0;
            padding-bottom: 0;
        }

        .row01 {
            flex-direction: column-reverse;
        }

        .row01 .col-sm-5 img {
            max-width: 142px;
            margin-top: 28px;
        }

        .txt01 {
            max-width: 295px;
            margin: 17px 0 0;
        }

        .row02 .col-sm-5 img {
            max-width: 198px;
            margin-top: 28px;
        }

        .txt02 {
            max-width: 311px;
            margin: 17px 0 0;
        }

        .row03 {
            flex-direction: column-reverse;
        }

        .row03 .col-sm-5 img {
            max-width: 184px;
            margin-top: 28px;
        }

        .txt03 {
            max-width: 286px;
            margin: 23px 0 0;
        }
    }

    .section04 {
        padding-bottom: 65px;

        h2 {
            font-size: 35px;
            line-height: 37px;
            max-width: 259px;
            margin: 50px auto 36px;
        }

        p {
            word-break: break-all;
            font-size: 17px;
        }
    }

    .animTextBack {
        background-size: 280px;
        animation: marquee 4s infinite linear;
    }

    @keyframes marquee {
        0% {
            background-position: 0;
        }

        100% {
            background-position: -280px;
        }
    }
}