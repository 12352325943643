.desktop {
    display: block;
}

.mobile {
    display: none;
}

header {
    background-color: #333333;
}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1088px;
    margin: 0 auto;
    height: 54px;
}

.logo-links {
    display: flex;
    align-items: center;
    gap: 60px;
}

.logo {
    display: flex;
}

.logo img {
    max-width: 32px;
}

ul {
    display: flex;
    gap: 34px;
    margin: 0;
    padding: 0;
    list-style: none;
}

ul li a {
    color: #B1B1B1;
    text-decoration: none;
    font-size: 16px;
}

ul li a:hover {
    opacity: .8;
}

.menu-logo-lang {
    display: flex;
    align-items: center;
}

.menu-icon {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #CFCACA;
    position: absolute;
    left: 37px;
}

.menu-icon img {
    display: block;
}

.links {
    display: none;
}

.links.show {
    display: block;
}

@media (min-width: 768px) {
    .menu-icon {
        display: none;
    }

    .links {
        display: block;
    }
}

@media (min-width: 600px) and (max-width: 900px) {
    ul {
        gap: 20px;
    }

    nav {
        padding: 0 20px;
    }

    .logo-links {
        gap: 30px;
    }
}

@media (max-width: 767px) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }

    nav {
        justify-content: center;
        max-width: 100%;
    }

    .logo-links a.logo {
        display: block;
        height: 32px;
        position: absolute;
    }

    .mobile.lang {
        position: absolute;
        right: 37px;
    }

    .menu-logo-lang {
        width: 100%;
        justify-content: space-around;
    }

    .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000000 0% 0% no-repeat padding-box;
        opacity: 0.38;
        z-index: 10;
        display: none;
    }

    .backdrop.show {
        display: block;
    }

    .links.show {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 11;
        padding: 25px 30px 17px;
        width: 185px;
        background: var(--unnamed-color-333333) 0% 0% no-repeat padding-box;
        background: #333333 0% 0% no-repeat padding-box;
        opacity: .9;
    }

    ul {
        display: block;
    }

    .close-icon {
        position: absolute;
        top: 27px;
        left: 199px;
        width: 31px;
        height: 31px;
        padding: 0;
        margin: 0;
        background: #ffffff;
        border-radius: 20px;
        color: #3D3935;
        font-size: 12px;
        outline: none;
        border: none;
        z-index: 11;
    }

    ul li a {
        color: #b1b1b1;
        font-size: 14px;
        display: block;
        margin-bottom: 8px;
    }

    li.mobile img {
        max-width: 107px;
        margin-bottom: 17px;
        display: block;
    }
}