#contato {
    background-color: #1A1818;
    margin: 0;
    padding: 60px 0;
    font-family: 'Roboto', sans-serif;
}

#contato nav {
    width: 100%;
    height: 40px;
    background-color: #333333;
    margin: 0;
}

#contato .item-grupo a {
    font-size: 12px;
    color: #CFCACA;
    line-height: 14px;
    text-decoration: none;
}

#contato .logo-group {
    text-align: center;
}

#contato li.item-grupo {
    padding: 10px 15px 0;
    list-style: none;
}

#contato ul.lista-grupo {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
}

#contato h1.titulo-inicio {
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    line-height: 25px;
    color: #CFCACA;
    width: 585px;
    text-align: center;
    margin: 42px auto 80px;
    font-weight: 300;
    font-style: italic;
}

#contato .container-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 70px;
    gap: 150px;
}

#contato .box-grupo {
    width: 300px;
    text-align: center;
    margin: 0px 10px 0;
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-wrap: wrap;
    /* align-content: space-between; */
    justify-content: center;
}

#contato a.whats {
    color: inherit;
    text-decoration: none;
    font-size: 17px;
    line-height: 23px;
    margin-right: 6px;
}

#contato p.phone {
    color: inherit;
    text-decoration: none;
    font-size: 17px;
    line-height: 23px;
    margin: 0;
}

#contato p.cep {
    font-family: 'Roboto', sans-serif;
    font-style: italic;
    line-height: 23px;
    font-size: 17px;
    margin-bottom: 0;
}

#contato a.mail {
    color: inherit;
    font-size: 17px;
    line-height: 23px;
    text-decoration: underline;
    font-weight: bold;
    margin-top: 10px;
}

#contato h2.titulo {
    margin: 0;
    font-size: 26px;
    line-height: 29px;
    color: #ffffff;
    font-family: "proxima-nova";
}

#contato a {
    color: #ffffff;
    text-decoration: none;
    text-decoration-color: transparent;
}

#contato h3.subtitulo {
    font-size: 17px;
    font-weight: 300;
    line-height: 23px;
    color: #DEC1A4;
    margin-top: 0;
    font-style: italic;
}

#contato .logo-footer {
    width: 170px;
    margin: 60px 0;
}

#contato .img-test-bg {
    background-image: url('https://essentialnutrition-upload-files.s3.amazonaws.com/site-essentiagroup/contatos/logo-essentia-group-desktop.svg');
    width: 145px;
    display: block;
    height: 145px;
    text-align: center;
    margin: 0 auto;
}

#contato .mobile {
    display: none;
}
#contato .logos-grupo.logos-desk {
    display: flex;
    gap: 50px;
    width: 100%;
    justify-content: center;
    align-items: flex-end;
}
@media(max-width:768px) {
    #contato .mobile {
        display: block;
    }

    #contato .img-test-bg {
        background-image: url('https://essentialnutrition-upload-files.s3.amazonaws.com/site-essentiagroup/contatos/logo-essentia-group-mobile.svg');
        width: 205px;
        display: block;
        height: 69px;
        text-align: center;
        margin: 0 auto;
    }

    #contato nav {
        display: none;
    }

    #contato .container-1 {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 50px;
    }

    #contato h1.titulo-inicio {
        max-width: 290px;
        font-size: 16px;
        line-height: 23px;
    }

    #contato .box-grupo {
        align-content: unset;
    }

    #contato li.item-grupo {
        width: 150px;
    }

    #contato ul.lista-grupo {
        flex-wrap: wrap;
        width: 100%;
    }

    #contato .logo-footer {
        margin: 20px auto;
        width: 150px;
    }

    .mobile {
        background-color: #333333;
    }

    #contato .logo-group.footer {
        text-align: left;
        padding: 50px 25px 25px;
        margin-top: 30px;
    }

    #contato a.links-logos-footer {
        display: inline-block;
        width: 100%;
        padding: 7px 0;
        color: #F5F5F5;
        text-decoration: none;
    }

    #contato .links-logos {
        padding-left: 25px;
        padding-bottom: 50px;
    }

    #contato a.links-logos-footer:hover {
        color: #ffffff;
    }

    #contato .logos-grupo.logos-desk {
        display: none;
    }
    #contato .slick-initialized .slick-slide{
        display: flex;
        align-items: center;
        height: 100px;
        margin-left: 50px;
    }
}

#contato .item-grupo a:hover {
    color: #ffffff;
}