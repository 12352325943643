.dropdown {
    position: relative;
    display: inline-block;
}

.dropbtn {
    color: #B1B1B1;
    font-size: 14px;
    border: none;
    cursor: pointer;
    background-color: transparent;
    padding: 10px 0px 10px 18px;
    display: flex;
    align-items: center;
    gap: 7px;
}

.dropdown-content {
    display: none;
    position: absolute;
    min-width: 160px;
    background-color: #333333;
}

.dropdown-content button {
    color: #B1B1B1;
    text-decoration: none;
    display: block;
    width: 100%;
    text-align: left;
    border: none;
    background: none;
    cursor: pointer;
    padding: 0px 0px 10px 18px;
}

.dropdown:hover .dropdown-content {
    display: block;
}

@media (min-width: 768px) {
    .dropdown-content button:hover {
        font-weight: bold;
        color: #f5f5f5;
    }
}